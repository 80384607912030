import React from "react";
import { SelectedContentProps } from "./selected-content.types";
import { SelectVariant } from "~/scalis-components/core/select/select.types";

export const SelectedContent: React.FC<SelectedContentProps> = ({
  variant,
  placeholder,
  icon,
  selectedValue,
  emptyTerm = 'an option',
  hasSelectedValue = false,
}) => {
  const defaultPlaceholder = placeholder ?? `Select ${emptyTerm}`;
  const value = hasSelectedValue ? selectedValue : defaultPlaceholder;

  switch (variant) {
    case SelectVariant.default: {
      return value;
    }

    case SelectVariant.inline: {
      return (
        <>
          {value}

          {icon}
        </>
      );
    }

    case SelectVariant.filter: {
      const nodesQuantity = React.Children.count(selectedValue);
      return nodesQuantity > 0
        ? `${nodesQuantity}・${placeholder}`
        : placeholder;
    }

    default:
      return null;
  }
};
