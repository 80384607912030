import { DEFAULT_EMPTY_TERM } from "./empty-list.constants";
import { EmptyListProps } from "./empty-list.types";

export const EmptyList = ({
  emptyTerm = DEFAULT_EMPTY_TERM,
  className,
}: EmptyListProps) => {
  return (
    <div className={`flex items-center justify-center py-12 ${className}`}>
      <span className="text-sm font-normal text-neutral-tertiary">
        {emptyTerm ? "No" : "None"} {emptyTerm} to show!
      </span>
    </div>
  );
};
