import { OptionIconProps } from "./option-icon.types";

export const OptionIcon = ({ icon, startAdornment }: OptionIconProps) => {
  if (!icon && !startAdornment) {
    return null;
  }

  const startAdornmentEl = (
    <div className="w-4" data-testid="start-adornment">
      {startAdornment}
    </div>
  );
  const iconEl = (
    <div className="w-2" data-testid="option-icon">
      {icon}
    </div>
  );

  return icon ? iconEl : startAdornmentEl;
};
