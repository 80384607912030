import { ReactNode } from "react";

export interface StatusTagProps extends React.HTMLAttributes<HTMLDivElement> {
  text: ReactNode; //TODO: are we sure that this will be ReactNode? It seems like string
  status?: StatusTagColors;
  endAdornment?: string;
  customColor?: string;
  contentContainerClassName?: string;
  statusIconClassName?: string;
  small?: boolean;
}

export enum StatusTagColors {
  green = "green",
  leafGreen = "leafGreen",
  limeGreen = "limeGreen",
  mossGreen = "mossGreen",

  red = "red",
  cherryRed = "cherryRed",

  yellow = "yellow",
  neonYellow = "neonYellow",
  yolkYellow = "yolkYellow",

  gray = "gray",
  violet = "violet",
  pink = "pink",
  teal = "teal",
  cyan = "cyan",
  disabled = "disabled",
  custom = "custom",
}

export type StatusStyleOption =
  | StatusTagColors.green
  | StatusTagColors.leafGreen
  | StatusTagColors.limeGreen
  | StatusTagColors.mossGreen
  | StatusTagColors.red
  | StatusTagColors.cherryRed
  | StatusTagColors.yellow
  | StatusTagColors.neonYellow
  | StatusTagColors.yolkYellow
  | StatusTagColors.gray
  | StatusTagColors.violet
  | StatusTagColors.pink
  | StatusTagColors.teal
  | StatusTagColors.cyan
  | StatusTagColors.cherryRed
  | StatusTagColors.disabled
  | undefined;
